<template>
  <div class="change-plan register-section">
    <div class="message-logo d-flex justify-content-around">
      <div>
        <div class="box-right">
          VOULEZ-VOUS MODIFIER VOTRE FORMULE OÙ LA DÉSACTIVER?
        </div>
      </div>
      <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
    </div>
    <div class="get-code">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <router-link to="/pricing" class="main-button w-100"
          >Choisir votre formule</router-link
        >
        <button class="main-button btn-danger">Désactiver</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePlan",
};
</script>
